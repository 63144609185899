<script setup lang="ts">
import { ValidationArgs } from '@vuelidate/core';
import { checkFormKeyError, showFormKeyError } from '@/common/utils';
import { Customer, CustomerItem } from '@/modules/orders/interfaces';

const props = defineProps({
  showClientType: {
    type: Boolean,
    required: false,
    default: false,
  },
  userFields: {
    type: Object as PropType<Map<keyof Customer, CustomerItem>>,
    required: true,
  },
});

const model = defineModel({
  required: true,
  type: Object as PropType<Customer>,
});

const emit = defineEmits(['confirm']);

const { t } = useI18n();
const legalPolicies = useLegalPolicies();
const showKeyboard = ref(false);
const showBackActions = ref(true);
const inputRef = ref<HTMLInputElement | null>(null);
const currentItem = ref<keyof Customer>();
const { userFields } = toRefs(props);
const { canContinue } = storeToRefs(legalPolicies);

const rules = computed((): ValidationArgs => ({
  name: {
    required: helpers.withMessage(t('USER_INFO.VALIDATIONS.REQUIRED', { field: t('USER_INFO.NAME') }), required),
    minLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.MIN_LENGTH', { field: t('USER_INFO.NAME'), length: 2 }), minLength(2)),
  },
  email: {
    minLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.EMAIL'), email),
  },
  phone: {
    minLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.MIN_LENGTH', { field: t('USER_INFO.PHONE'), length: 10 }), minLength(10)),
    maxLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.MAX_LENGTH', { field: t('USER_INFO.PHONE'), length: 10 }), maxLength(10)),
  },
  taxIdentificationNumber: {
    minLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.MIN_LENGTH', { field: t('USER_INFO.DOCUMENT_ID'), length: 6 }), minLength(6)),
    maxLength: helpers.withMessage(t('USER_INFO.VALIDATIONS.MAX_LENGTH', { field: t('USER_INFO.DOCUMENT_ID'), length: 10 }), maxLength(10)),
  },
}));

const v$ = useVuelidate(rules, model);

const allowEmailKeyboard = computed(() => currentItem.value === 'email');

const isNumberKeyboard = computed(() => currentItem.value === 'phone'
  || currentItem.value === 'taxIdentificationNumber');

/**
 * Trigger $touch event in selected item, close current
 * keyboard and emits 'confirm' event if it's necessary.
 */
function closeKeyBoard() {
  v$.value[currentItem.value!].$touch();

  showKeyboard.value = false;

  if (userFields.value.size === 1) emit('confirm');
}

/**
 * Set value to 'currentItem' ref and allow
 * keyboard use to user.
 * @param {keyof Customer} key - Customer key
 */
function setCurrentField(event: Event, key: keyof Customer) {
  inputRef.value = event.target as HTMLInputElement;
  currentItem.value = key;
  // Show user keyboard
  showKeyboard.value = true;
}
</script>

<template>
  <div class="relative customer-info">
    <div
      v-if="showClientType"
      class="flex items-center justify-center -mb-6 client-type"
    >
      <label for="regular">
        <input
          id="regular"
          type="radio"
          name="regular"
          class="radio checked:bg-primary-600"
          value="false"
          v-model="model.clientType"
        />
        {{ t('USER_INFO.CLIENT_TYPE.REGULAR') }}
      </label>

      <label for="company">
        <input
          id="company"
          type="radio"
          name="company"
          class="radio checked:bg-primary-600"
          value="true"
          v-model="model.clientType"
        />
        {{ t('USER_INFO.CLIENT_TYPE.COMPANY') }}
      </label>
    </div>

    <template
      v-for="[key, item] in userFields"
      :key="item.label"
    >
      <div class="customer-item">
        <span
          class="block ml-2 text-6xl icon"
          :class="`icon-${item.icon}`"
        />

        <label>
          <span class="relative pr-5 text-2xl">
            {{ item.label }}
            <span
              v-if="v$[key].required"
              class="absolute right-0 text-4xl text-red-600"
            >
              *
            </span>
          </span>

          <input
            v-model="model[key]"
            @focus="setCurrentField($event, key)"
            @keyup.enter="closeKeyBoard()"
            :placeholder="item.placeholder"
            class="item-input"
          />

          <p
            v-if="item.info.length > 0 && showClientType"
            class="flex gap-2 py-3 text-lg italic leading-none label-text-alt text-info"
          >
            <span class="icon icon-info-circle" /> {{ item.info }}
          </p>
        </label>

        <div
          v-if="checkFormKeyError(v$, key)"
          class="absolute label top-full"
        >
          <span class="text-2xl leading-none label-text-alt text-error">{{ showFormKeyError(v$, key) }}</span>
        </div>
      </div>
    </template>

    <LegalPolicies />

    <KioskButton
      color="primary"
      text-size="small"
      class="absolute right-10 bottom-7"
      :disabled="v$.$invalid || !canContinue"
      @click="$emit('confirm')"
    >
      {{ t('NEXT') }}
    </KioskButton>
  </div>

  <!-- User keyboard -->
  <SlideTransition
    class="fixed bottom-0"
    @before-enter="showBackActions = false"
    @after-leave="showBackActions = true"
  >
    <template v-if="showKeyboard">
      <NumbersKeyboard
        v-if="isNumberKeyboard"
        v-model="model[currentItem!]"
        :input-ref="inputRef"
        :max-length="10"
        @close="closeKeyBoard()"
      />

      <CustomKeyboard
        v-else
        v-model="model[currentItem!]"
        :show-email-suggestions="allowEmailKeyboard"
        :input-ref="inputRef"
        @close="closeKeyBoard()"
      />
    </template>
  </SlideTransition>
</template>

<style scoped>
.customer-info {
  @apply flex flex-col items-center justify-center gap-10 my-2 p-8;
}

.customer-item {
  @apply shadow-xl flex justify-center items-center gap-8 px-5 py-3 w-[556px] relative;
}

.item-input {
  @apply h-14 w-96 bg-neutral-200 placeholder:text-neutral-300 text-[40px] font-medium leading-[101px] tracking-[-1.263px] px-4 focus:outline-none;
}

.client-type label {
  @apply mx-5 flex items-center text-2xl;
}

.client-type input {
  @apply mr-4;
}
</style>
