import { KioskMetadata } from '@/common/interfaces/checkUpdates';

const INITIAL_STATE: KioskMetadata = {
  kioskSettings: null,
  storeId: '',
  integration: '',
  banners: [],
  clusterSettings: null,
  carouselImages: [],
  customImages: null,
  brandSettings: null,
  pausedBanner: null,
  kioskSchedules: null,
};

export const useMetadataStore = defineStore('metadata', {
  state: (): KioskMetadata => ({ ...INITIAL_STATE }),

  getters: {
    mainLogo: (state) => state.customImages?.brandImage ?? null,
    timelineImage: (state) => state.customImages?.timelineIcon ?? null,
    mainHeaderImage: (state) => state.customImages?.mainHeader ?? null,
    mainFooterImage: (state) => state.customImages?.mainFooter ?? null,
    modalHeaderImage: (state) => state.customImages?.modalHeader ?? null,
    modalFooterImage: (state) => state.customImages?.modalFooter ?? null,
    paymentImage: (state) => state.customImages?.paymentMethods ?? null,
    loaderImage: (state) => state.customImages?.loader ?? null,
    startButton: (state) => state.kioskSettings?.options.startButton ?? true,
    hideCash: (state) =>
      !(state.kioskSettings?.options.cashPayment),
    hideCoupons: (state) =>
      !(state.kioskSettings?.options.coupons),
    hideTableNumber: (state) => !(state.kioskSettings?.options.tableNumber),
    colors: (state) => state.brandSettings?.colors ?? null,
    showPaginatedMG: (state) => state.brandSettings?.layout?.modifiers === 'steps' ?? null,
    categoryColumns: (state) => state.brandSettings?.layout?.category ?? null,
    customFont: (state) => state.brandSettings?.fonts ?? null,
    priceDecimals: (state) => (state.kioskSettings?.currency.decimals ? 2 : 0),
    inactivity: (state) => state.brandSettings?.inactivity,
    showClientType: (state) => state.clusterSettings?.customerInfo.showClientType,
    nestedModifiersStyle: (state) => state.brandSettings?.layout.nestedModifiers,
    counterStyle: (state) => state.brandSettings?.buttons.counterStyle,
    buttonsSettings: (state) => state.brandSettings?.buttons ?? null,
    cardsRadius: (state) => state.brandSettings?.cards.radius,
    isKioskDisabled: (state) => !!state.pausedBanner,
    cardRadiusClass: (state) => state.brandSettings?.cards.radius,
    legal: (state) => state.brandSettings?.legal ?? null,
  },

  actions: {
    /**
     * Get updates
     */
    async getMetadata(): Promise<void> {
      const { clusterSettings, kioskSettings, banners, kioskSchedules } = await requests.get(`${this.storeId}/metadata`, {
        params: {
          timestamp: new Date().toLocaleString('sv'),
        },
      });
      // Set request values
      this.$patch({ clusterSettings, kioskSettings, banners, kioskSchedules });
    },

    async getStaticData(storeId: string): Promise<void> {
      const { customImages, brandSettings, carouselImages } = await requests.get(
        `${storeId}/metadata/static`,
        { params: { integration: false } },
      );
      // Set request values
      this.$patch({ customImages, brandSettings, carouselImages });
    },

    setPausedBanner(bannerUrl: string): void {
      this.pausedBanner = bannerUrl;
    },
  },
});
