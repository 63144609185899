export const fr = {
  TABLE_NUMBER: 'Entrez le numéro de table',
  TABLE: 'Table',
  NEXT: 'Suivant',
  BACK: 'Retour',
  OPTIONAL: 'Optionnel',
  DONE: 'Prêt',
  SELECT_LANGUAGE: 'Choisissez la langue',
  SELECT_ORDER_TYPE: 'Où voulez-vous manger?',
  SOMETHING_WENT_WRONG: 'Une erreur s\'est produite, veuillez réessayer plus tard',
  INTERNAL_ERROR: 'Erreur interne',
  EAT_HERE: 'Manger sur place',
  RESTART: 'Redémarrer',
  RETRY: 'Réessayer',
  CONTINUE: 'Continuer',
  ACCEPT: 'Accepter',
  EDIT: 'Modifier',
  DELETE: 'Supprimer',
  TAKE_AWAY: 'À emporter',
  PICK_UP_COUNTER: 'Retrait au comptoir',
  CRAVING_SELECTION: 'De quoi avez-vous envie?',
  TOTAL: 'Total',
  TAXES: 'Taxes',
  SUBTOTAL: 'Sous-total',
  ADD: 'Ajouter',
  NOT_AVAILABLE: 'Indisponible',
  ADD_CART: 'Ajouter au panier',
  SAVE: 'Enregistrer',
  CUSTOMIZE: 'Personnaliser',
  QUANTITY: 'Montant',
  PRICE: 'Prix',
  ITEMS: 'Articles',
  SKIP: 'Passer',
  TYPE_HERE: 'Écrivez ici',
  ADD_VOUCHER: 'Ajouter une promotion',
  SCAN_VOUCHER: 'Scannez le code QR code',
  ADD_ANOTHER_VOUCHER: 'Ajouter un autre promotion',
  GO_BACK: 'Retour',
  VOUCHER_REJECTED: 'Promotion refusé',
  VOUCHER_REJECTED_DETAIL: 'Désolé, une erreur est survenue lors de la lecture du QR code. Veuillez réessayer',
  CASH: 'Espèces',
  CARD: 'Carte bancaire',
  PAY_ON_SITE: 'Payer à la caisse',
  MAIN_MENU: 'Menu principal',
  MODIFIERS_BACK_TITLE: 'Voulez-vous revenir au menu?',
  MODIFIERS_BACK_DESCRIPTION: 'Vous perdrez les modifications apportées à ce produit',
  CANCEL: 'Annuler',
  CONTAINS_ALCOHOL: 'Contient de l\'alcool',
  TIMELINE: {
    CHOOSE_PRODUCT: 'Choisir un produit',
    CUSTOMIZATION: 'Personnaliser',
    CONFIRM: 'Confirmer',
    PAY: 'Payer',
  },
  ORDER: {
    BACK_MENU: 'Retour au menu',
    BACK_CART: 'Retour au panier',
    UPGRADE_MODIFIER_QUESTION: 'Voulez-vous augmenter votre combo?',
    ACCEPT_UPGRADE: 'Oui',
    DENY_UPGRADE: 'Non',
    UPGRADE_MODIFIER_DESC: 'Le combo va s\'agrandir',
    UPGRADE_LABEL: 'Combo agrandi',
  },
  CANCEL_ORDER: {
    QUESTION: 'Voulez-vous annuler cette commande?',
    ADVICE: 'Les modifications seront perdues et vous retournerez au début',
    CONFIRMATION: 'Oui, annuler',
  },
  DELETE_ITEM: {
    QUESTION: 'Voulez-vous supprimer ce produit?',
    ADVICE: 'Vous allez éliminer le produit',
    CONFIRMATION: 'Oui, supprimer',
  },
  INACTIVITY: {
    TITLE: 'Vous êtes toujours là?',
    ADVICE: 'Dans quelques secondes, la commande sera réinitialisée et vous retournerez au début',
    QUESTION: 'Voulez-vous continuer votre commande?',
  },
  MODIFIERS_MODAL: {
    TITLE: 'Personnalisez les ingrédients',
  },
  CHECKOUT: {
    CLIENT_INFO_TITLE: 'Entrez votre nom, nous vous appellerons lorsque votre commande sera prête',
    SUMMARY_TITLE: 'Consultez le récapitulatif de votre achat et effectuez le paiement',
    SUMMARY_PAYMENT_MESSAGE: 'Déplacez ou glissez la carte dans le terminal pour finaliser votre achat',
    GET_CLOSE_CARD: 'Insérez la carte dans le terminal',
    REJECTED_PAY: 'Paiement refusé',
    REJECTED_PAY_AGAIN: 'Paiement refusé à nouveau',
    REJECTED_DESCRIPTION: 'Nous sommes désolés pour ce qui s\'est passé, veuillez <b>passer à la caisse</b> pour passer votre commande',
    CASH_PAY: 'Payer en espèces',
    PROCESSING: 'Traitement de votre commande',
    CANCEL: 'Pour annuler une commande',
  },
  SUCCESS_ORDER: {
    TITLE: 'Commande créée avec succès',
    DESCRIPTION: 'Veuillez <b>vous rendre à la caisse enregistreuse spéciale du Borne</b> et y payer votre commande',
    ORDER: 'Numéro de commande',
    CARD_DESCRIPTION: 'votre paiement a réussi, <b>profitez de votre commande</b>',
    POS_FAIL: 'Veuillez <b>passer à la caisse</b> avec la facture pour vérifier votre commande',
  },
  SUCCESS_ORDER_CARD: {
    TITLE: 'Paiement effectué avec succès',
    DESCRIPTION: 'Merci pour votre achat, votre commande sera prête dans quelques minutes',
    VOUCHER: 'Promotion utilisé avec succès',
  },
  USER_INFO: {
    NAME: 'Nom ou raison sociale',
    COMPANY_NAME: 'Raison sociale',
    EMAIL: 'E-mail',
    DOCUMENT_ID: 'Numéro d\'identification / SIRET',
    PHONE: 'Numéro de téléphone',
    PHONE_PLACEHOLDER: '3101234567',
    EMAIL_PLACEHOLDER: "mail{'@'}mail.com",
    NAME_PLACEHOLDER: 'Nom Prénom',
    DOCUMENT_PLACEHOLDER: 'Pas de chiffre de contrôle',
    VALIDATIONS: {
      REQUIRED: 'Le champ "{field}" est obligatoire',
      EMAIL: 'Le format de l\'e-mail n\'est pas valide',
      BILLING_NEEDED: 'Données nécessaires à l\'émission d\'une facture électronique',
      BILLING_ID_NEEDED: 'Données nécessaires pour émettre une facture électronique et cumuler des points',
      BILLING_NAMES_NEEDED: 'Les noms et prénoms, ou la raison sociale sont nécessaires pour émettre une facture électronique',
      MIN_LENGTH: 'Le champ "{field}" doit contenir au moins {length} caractères',
      MAX_LENGTH: 'Le champ "{field}" doit contenir au maximum {length} caractères',
    },
    CLIENT_TYPE: {
      REGULAR: 'Personne physique',
      COMPANY: 'Entité juridique',
    },
  },
  ALCOHOL: {
    TITLE: 'Attention',
    GALLERY_MESSAGE: 'Ce produit contient de l\'alcool.\nVeuillez confirmer que vous avez l\'âge légal avant de procéder à l\'achat',
    MODIFIERS_MESSAGE: 'Certaines options de ce produit contiennent de l\'alcool.\nVeuillez confirmer que vous avez l\'âge légal avant de choisir vos options.',
    CONFIRM: '+18 ans',
    CANCEL: 'Je suis mineur',
  },
  SPACE: 'espace',
  ORDER_HERE: 'Appuyez pour commander!',
  PRODUCTS: {
    OUT_OF_STOCK: 'Épuisé',
  },
  LEGAL: {
    TERMS_AND_CONDITIONS: "J'accepte ,es Conditions d'utilisation",
    PRIVACY_POLICY: "J'accepte la Politique de Confidentialité",
  },
};

export default fr;
